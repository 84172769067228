import React, { useEffect, useState } from 'react';
import { sortList } from '../../../data/data';
import { useProperty } from '../../../contexts/property';
import { Dropdown } from 'flowbite-react';
import { MdOutlineSort } from 'react-icons/md';
import { Order } from '../../../types/types';
import { useTranslation } from 'react-i18next';
import { useGlobal } from '../../../contexts/global';
// import { useLocation } from 'react-router-dom';

const SortBy = () => {
  const { filter, setFilter, debouncedFetchProperties, properties } = useProperty(); //fetchMaxListingPrice
  const { t } = useTranslation();
  // const location = useLocation();
  const [selectedSort, setSelectedSort] = useState(sortList[3]);
  const { keyword } = useGlobal();
  const isMobile = window.innerWidth <= 768;

  const handleOrder = (option: any) => {
    let newOrder = { column: 'createdAt', direction: 'DESC' } as Order;
    switch (option.category) {
      case sortList[0].category:
        newOrder = { column: 'listingPrice', direction: 'ASC' };
        break;
      case sortList[1].category:
        newOrder = { column: 'listingPrice', direction: 'DESC' };
        break;
      case sortList[2].category:
        newOrder = { column: 'viewCount', direction: 'DESC' };
        break;
      case sortList[3].category:
        newOrder = { column: 'createdAt', direction: 'DESC' };
        break;
      default:
        break;
    }

    setFilter({ ...filter, order: newOrder });
  };

  const handleChange = (option: any) => {
    setSelectedSort(option);
    handleOrder(option);
  };

  useEffect(() => {
    // to trigger first search on Mobile. Filter is not mounted yet
    if (isMobile) {
      debouncedFetchProperties({ ...filter, keyword }, 1, properties);
    }
  }, [filter?.order, keyword]); // to have the query params working: filter?.city

  if (isMobile) {
    return (
      <div className="w-full justify-end text-sm flex text-defaultText px-0 sm:px-12 mt-14 sm:mt-4">
        <div className="flex items-center">
          <MdOutlineSort className="h-5 w-5" />
          <select
            value={selectedSort.category} // assuming `selectedSort.category` stores the selected option's category
            onChange={(e) =>
              handleChange(sortList.find((option) => option.category === e.target.value))
            }
            className="text-defaultText bg-transparent border-none outline-none text-sm focus:ring-0"
          >
            {sortList.map((sortOption, index) => (
              <option key={index} value={sortOption.category}>
                {t(sortOption.title)}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full justify-end text-sm flex text-defaultText!important px-4 sm:px-12 mt-14 sm:mt-4">
      <Dropdown
        dismissOnClick={true}
        arrowIcon={true}
        inline={true}
        label={
          <div className="flex gap-x-2 text-defaultText items-center">
            <MdOutlineSort className="h-5 w-5" />
            {t(selectedSort.title)}
          </div>
        }
      >
        {sortList.map((sortOption, index) => (
          <Dropdown.Item key={index} onClick={() => handleChange(sortOption)}>
            {t(sortOption.title)}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
};

export default SortBy;
