import React, { useEffect } from 'react';
import { LAND } from '../../../constants/constant';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Box = ({ isActive, label, hoverLabel, onClick }: any) => {
  const { t } = useTranslation();

  return (
    <div className="group flex relative justify-center center-items" onClick={onClick}>
      {hoverLabel && (
        <div className="absolute text-center  whitespace-nowrap text-xs rounded-sm bg-gray-500 text-white font-bold px-2 py-1 opacity-0 group-hover:opacity-100 transition duration-500 ease-in-out transform -translate-y-5 group-hover:-translate-y-8">
          {hoverLabel}
        </div>
      )}
      <div
        className={`place-content-center cursor-pointer flex-wrap justify-center center-items h-24 w-24 text-textDefault flex rounded-2xl border-deehiy ${
          isActive
            ? ' border-2 active:scale-90  shadow-md border-deehiy bg-deehiy-veryLight scale-100 bg-opacity-80 '
            : ' border bg-[#fcfcfc] bg-opacity-80'
        }`}
      >
        {t(label)}
      </div>
    </div>
  );
};

const SelectSellRentType = ({ editedProperty, setEditedProperty, setCanClickNext }: any) => {
  const navigate = useNavigate();

  const handleClick = (type: string) => {
    // LAND is not allowed for RENT and OFF-PLAN
    let currentPropertyType = editedProperty.propertyType;
    let updatedPropertyType =
      currentPropertyType === LAND && type !== 'sale' ? null : currentPropertyType;

    switch (type) {
      case 'rent':
        setEditedProperty({
          ...editedProperty,
          isForRent: true,
          isForSale: false,
          isOffPlan: false,
          propertyType: updatedPropertyType,
        });
        break;
      case 'sale':
        setEditedProperty({
          ...editedProperty,
          isForRent: false,
          isForSale: true,
          isOffPlan: false,
          propertyType: updatedPropertyType,
        });
        break;
      case 'offPlan':
        setEditedProperty({
          ...editedProperty,
          isForRent: false,
          isForSale: false,
          isOffPlan: true,
          propertyType: updatedPropertyType,
        });
        break;
      case 'both':
        setEditedProperty({
          ...editedProperty,
          isForRent: true,
          isForSale: true,
          isOffPlan: false,
          propertyType: updatedPropertyType,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      editedProperty.isForSale === true ||
      editedProperty.isForRent === true ||
      editedProperty.isOffPlan === true
    ) {
      setCanClickNext(true);
    }
    console.log('editedProperty', editedProperty);
  }, [editedProperty, setCanClickNext]);

  return (
    <div className="flex flex-col w-full items-center justify-center ">
      <div className="space-y-6 pb-12 sm:pb-24 px-6 sm:px-0">
        <div className="text-2xl font-bold">List your property for free!</div>
        <div>
          {' '}
          it takes just 2 minutes, and your listing can be seen by buyers and renters worldwide. No
          hidden fees, no commission &ndash; <b>just exposure</b>.
        </div>
        <div>
          {' '}
          Let's go! How would you like to list your property ?{' '}
          <span
            className="text-xs text-deehiy font-bold whitespace-nowrap"
            onClick={() => navigate('/help/article/1116')}
          >
            {' '}
            learn how it works
          </span>
        </div>
      </div>

      <div className="sm:flex w-full grid grid-cols-2 gap-12 sm:gap-0 sm:justify-evenly">
        <Box
          onClick={() => handleClick('rent')}
          isActive={editedProperty.isForRent && !editedProperty.isForSale}
          label="Rent"
          hoverLabel=""
        />
        <Box
          onClick={() => handleClick('sale')}
          isActive={editedProperty.isForSale && !editedProperty.isForRent}
          label="Sell"
          hoverLabel=""
        />
        <Box
          onClick={() => handleClick('both')}
          isActive={editedProperty.isForSale && editedProperty.isForRent}
          label="Sell & Rent"
          hoverLabel=""
        />
        <Box
          onClick={() => handleClick('offPlan')}
          isActive={editedProperty.isOffPlan}
          label="Off-plan"
          hoverLabel=""
        />
      </div>
    </div>
  );
};

export default SelectSellRentType;
