import React, { useState, useEffect } from 'react';
import LocationSearch from '../steps/propertySteps/Location/LocationSearch';
import { RxCross2 } from 'react-icons/rx';
// import LeavePageWarning from './LeavePageWarning';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { updatePreferredAreas } from '../../services/auth/userService';
import { useAuth } from '../../contexts/auth';
import { initialFilter } from '../../initialStates/initialStates';
import { getAllProperties } from '../../services/propertyService';
import { MarketFilter } from '../../types/types';
import { FiChevronRight } from 'react-icons/fi';
import { useProperty } from '../../contexts/property';
import {
  RENTER_TYPE,
  BUYER_TYPE,
  SELLER_TYPE,
  LANDLORD_TYPE,
  AGENT_TYPE,
} from '../../constants/constant';

export const CitySelector = ({
  inputValue,
  setInputValue,
  selectedCities,
  setSelectedCities,
  role,
  showCityCount = false,
  setTotalCityCount,
}: any) => {
  const [formattedText, setFormattedText] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Added state for error message
  const [cityCounts, setCityCounts] = useState<{ [city: string]: number }>({});

  const calculateTotalCityCount = () => {
    const totalCount = Object.values(cityCounts).reduce((acc, count) => acc + Number(count), 0);
    setTotalCityCount(totalCount);
  };

  const handleAddCity = async () => {
    if (inputValue.trim() && !selectedCities.includes(inputValue)) {
      setSelectedCities([...selectedCities, formattedText]);
      setInputValue(''); // Clear input after adding
      setErrorMessage(''); // Clear error message on successful addition
    }
  };

  const handleRemoveCity = async (city: string) => {
    setErrorMessage(''); // Clear error message on successful addition
    setSelectedCities(selectedCities.filter((c: any) => c !== city));

    // Remove city from the count list
    setCityCounts((prevCounts) => {
      const updatedCounts = { ...prevCounts };
      delete updatedCounts[city];
      return updatedCounts;
    });
  };

  // Fetch the property count for each city
  const fetchCityCount = async (city: string) => {
    const filterWithCity: MarketFilter = {
      ...initialFilter,
      city,
      isForSale: role === BUYER_TYPE, // If role is buyer, fetch properties for sale
      isForRent: role === RENTER_TYPE,
    };
    try {
      const response = await getAllProperties(filterWithCity, 1);
      let totalCount = response.data.pagination.totalCount;
      totalCount = totalCount > 999 ? '+999' : totalCount;
      // Update the city count state
      setCityCounts((prevCounts) => ({
        ...prevCounts,
        [city]: totalCount,
      }));
    } catch (error: any) {
      console.error('Error fetching count:', error);
    }
  };

  useEffect(() => {
    if (selectedCities.length >= 5) {
      // Check if already 5 cities
      setErrorMessage('You can only add up to 5 cities.'); // Set error message
      return;
    }
    handleAddCity();
  }, [formattedText]);

  useEffect(() => {
    // Fetch property count for each city when selectedCities changes
    selectedCities.forEach((city: string) => {
      if (!cityCounts[city] && showCityCount) {
        fetchCityCount(city); // Fetch the count only if it hasn't been fetched before
      }
    });
  }, [selectedCities]);

  useEffect(() => {
    calculateTotalCityCount();
  }, [cityCounts]);

  return (
    <div className="w-72">
      {errorMessage && (
        <div className="text-red-500 text-sm text-center pb-2 mt-[-2]">{errorMessage}</div>
      )}
      <div className=" flex border border-deehiy rounded w-full p-2 bg-white">
        <LocationSearch
          hasOnlyCity={true}
          label=""
          locationString={inputValue}
          setFormattedMainText={setFormattedText}
          setLocationString={setInputValue}
          // setLocationObject={setLocationObject}
        />
      </div>
      <div className=" flex flex-col justify-start w-full mt-2 h-44">
        <div className="text-sm pt-3 pb-1">Your cities:</div>
        {selectedCities.map((city: string, index: number) => (
          <div key={index}>
            <div className=" flex justify-between text-sm text-deehiy pr-2 py-1 border-none items-center">
              <div className=" line-clamp-1 w-56">{city}</div>
              <div className="flex items-center justify-end w-40">
                <span>
                  {cityCounts[city] !== undefined ? `(${cityCounts[city]} listings)` : ''}
                </span>
                <button
                  className="rounded-full hover:bg-gray-100 ml-3"
                  onClick={() => handleRemoveCity(city)}
                >
                  <RxCross2 className="text-lg" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CityPicker = ({ role }: any) => {
  const { currentUser } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  // const [formattedText, setFormattedText] = useState('');
  // const [locationObject, setLocationObject] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setFilter, setTempFilter, filter, tempFilter } = useProperty();
  const [totalCityCount, setTotalCityCount] = useState(0);

  // const handleAddCity = async () => {
  //   if (inputValue.trim() && !selectedCities.includes(inputValue)) {
  //     setSelectedCities([...selectedCities, formattedText]);
  //     setInputValue(''); // Clear input after adding
  //   }
  // };

  // const handleRemoveCity = async (city: string) => {
  //   setSelectedCities(selectedCities.filter((c) => c !== city));
  // };

  const handleStart = async () => {
    setIsLoading(true);
    const citiesParam = decodeURIComponent(encodeURIComponent(selectedCities.join(',')));

    const selectedCitiesJson = JSON.stringify(selectedCities);

    await updatePreferredAreas(currentUser?.id, selectedCitiesJson)
      .catch((e: any) => {
        alert(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

    if (totalCityCount === 0 && (role === BUYER_TYPE || role === RENTER_TYPE)) {
      navigate('/users/addhunt'); // Redirect to addhunt if no listings in selected cities
      return;
    }

    switch (role) {
      case BUYER_TYPE:
        setFilter({
          ...filter,
          isForSale: true,
          isForRent: false,
          city: citiesParam,
        });

        setTempFilter({
          ...tempFilter,
          isForSale: true,
          isForRent: false,
          city: citiesParam,
        });
        // navigate(`/?t=buy&location=${citiesParam}`);
        navigate(`/`);
        break;
      case RENTER_TYPE:
        setFilter({
          ...filter,
          isForRent: true,
          isForSale: false,
          city: citiesParam,
        });

        setTempFilter({
          ...tempFilter,
          isForRent: true,
          isForSale: false,
          city: citiesParam,
        });
        // navigate(`/?t=rent&location=${citiesParam}`);
        navigate(`/`);
        break;
      case SELLER_TYPE:
        navigate('/users/dashboard');
        break;
      case LANDLORD_TYPE:
        navigate('/users/dashboard');
        break;
      case AGENT_TYPE:
        navigate('/users/dashboard');
        break;
      default:
        navigate('/');
        break;
    }
  };

  // useEffect(() => {
  //   if (selectedCities.length > 5) {
  //     return;
  //   }
  //   handleAddCity();
  // }, [formattedText]);

  return (
    <div className="flex flex-col justify-center items-center pt-16">
      <div className="flex mx-auto space-y-6 items-center justify-center flex-col">
        {role === 'renter' || role === 'buyer' ? (
          <>
            <h1 className="text-xl sm:text-2xl text-center">
              Where are you looking to buy or rent?
            </h1>
            <div className="text-base sm:text-xl px-8">
              {' '}
              Let us know your preferred areas to help match you with the perfect property.
            </div>
          </>
        ) : (
          <>
            <h1 className="text-xl sm:text-2xl text-center">Where do you sell or rent out?</h1>
            <div className="text-base sm:text-xl px-8">
              {' '}
              Select up to 5 cities, and our AI will match you with the best leads.
            </div>
          </>
        )}

        <div className="text-xs">You can select multiple cities (5 max).</div>
        {/* <div className="w-72">
          <div className=" flex border border-deehiy rounded w-full p-2">
            <LocationSearch
              hasOnlyCity={true}
              label=""
              locationString={inputValue}
              setFormattedMainText={setFormattedText}
              setLocationString={setInputValue}
              // setLocationObject={setLocationObject}
            />
          </div>
          <div className=" flex flex-col justify-start w-full mt-4 h-64">
            <div className="text-sm py-3">Your cities:</div>
            {selectedCities.map((city: string, index: number) => (
              <div key={index}>
                <div className=" flex justify-between text-sm text-deehiy pr-2 py-2 border-none">
                  <span>{city}</span>
                  <button
                    className="rounded-full hover:bg-gray-100 ml-3"
                    onClick={() => handleRemoveCity(city)}
                  >
                    <RxCross2 />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        <CitySelector
          role={role}
          showCityCount={true}
          inputValue={inputValue}
          selectedCities={selectedCities}
          setSelectedCities={setSelectedCities}
          setInputValue={setInputValue}
          setTotalCityCount={setTotalCityCount}
        />

        {isLoading && <Loader />}
      </div>
      {role === 'agent' || role === 'landlord' || role === 'seller' ? (
        <div className="text-center text-sm flex flex-col justify-center items-center max-w-[400px] space-y-6">
          <div className="font-bold text-xl text-deehiy">Ready to list your property?</div>
          <div>
            In just 2 minutes your listing can be exposed to hundreds of active buyers and renters
            in your city.
          </div>
          <button className="primary-btn" onClick={() => navigate('/addproperty')}>
            List now
          </button>
          <div
            className="font-bold flex gap-x-2 cursor-pointer items-center"
            onClick={() => navigate('/users/dashboard')}
          >
            Take me to the dashboard
            <FiChevronRight />
          </div>
        </div>
      ) : (
        <button
          className="form-next-btn mx-auto text-center justify-center items-center flex-col flex space-y-4 w-48 p-3"
          onClick={handleStart}
          disabled={isLoading}
        >
          {totalCityCount === 0 ? 'Create a hunt' : "Let's start"}
        </button>
      )}
    </div>
  );
};

export default CityPicker;
